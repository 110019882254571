import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { MainComponent, MaxDirective, MinDirective} from './components/main/main.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import {ToastrModule} from "ngx-toastr";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AuthInterceptor} from "./auth.service";
import { RedirectGuard } from './guards/redirect.guard';
import {CookieService} from "ngx-cookie-service";
import {GlobalNavModule} from 'ngx-global-nav';
import {analytics} from 'noc-analytics';
import {NOC_ANALYTICS} from 'ngx-shared-services';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {GlobalFooterModule} from 'ngx-global-contact-footer';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    MaxDirective,
    MinDirective,
    LoadingScreenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      closeButton: true
    }),
    GlobalNavModule.forRoot({namespace: 'ngx-global-nav.'}),
    GlobalFooterModule.forRoot({namespace: 'ngx-global-contact-footer.'}),
    GlobalFooterModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: NOC_ANALYTICS, useValue: analytics },
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
