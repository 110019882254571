import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SharedService} from "./shared.service";
import {environment} from "../environments/environment";
import {Observable} from "rxjs/internal/Observable";

@Injectable({
  providedIn: 'root'
})
export class EventManagementService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  api_url = environment.api_url;

  get(url, params) : Observable<any>{
    const p = this.sharedService.transformParams(params);
    return this.http.get(this.api_url + url, p);
  }

  put(url, body, params): Observable<any> {
    const p = this.sharedService.transformParams(params);
    return this.http.put(this.api_url + url, body, p);
  }

  post(url, body, params) : Observable<any>{
    const p = this.sharedService.transformParams(params);
    return this.http.post(this.api_url + url, body, p);
  }

  delete(url, params) : Observable<any>{
    const p = this.sharedService.transformParams(params);
    return this.http.delete(this.api_url + url, p);
  }
}
