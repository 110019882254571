import {Component, OnInit, Input, Directive, forwardRef, EventEmitter} from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, Validators, ValidatorFn } from "@angular/forms";
import {EventManagementService} from "../../event-management.service";
import {Subject} from "rxjs/internal/Subject";
import { catchError, map, takeUntil } from "rxjs/operators";
import {SharedService} from "../../shared.service";
import {Operator} from "../../classes/operator";
import {Program} from "../../classes/program";
import {Product} from "../../classes/product";
import {Title} from "@angular/platform-browser";
import {Template} from "../../classes/template";
import {FilterType} from "../../classes/filter_type";
import { forkJoin, of } from 'rxjs';
import * as _ from 'lodash';

@Directive({
  selector: "[minNum][formControlName],[minNum][formControl],[minNum][ngModel]",
  providers: [
    { provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MinDirective),
      multi: true }
  ]
})
export class MinDirective implements Validator {
  private _validator: ValidatorFn;

  @Input() public set minNum(value: string) {
    this._validator = Validators.min(parseInt(value, 10));
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    return this._validator(control);
  }
}

@Directive({
  selector: "[maxNum][formControlName],[maxNum][formControl],[maxNum][ngModel]",
  providers: [
    { provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MaxDirective),
      multi: true }
  ]
})
export class MaxDirective implements Validator {
  private _validator: ValidatorFn;
  @Input() public set maxNum(value: string) {
    this._validator = Validators.max(parseInt(value, 10));
  }

  public validate(control: AbstractControl): { [key: string]: any } {
    const temp = this._validator(control);
    return temp;
  }
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.pug',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private eventManagementService: EventManagementService, private sharedService: SharedService, private titleService:Title) {
    this.titleService.setTitle('Workflow Admin');
  }

  @Input() loadingScreen;

  allOperators: Array<Operator> = null;
  allPrograms: Array<Program> = null;
  allProducts: Array<Product> = null;
  allTemplates = {NOTIFICATION: null, SCHEDULE: null};
  allFilters = {NOTIFICATION: [], SCHEDULE: []};
  scheduleFilters;
  notificationFilters;
  selectedOperator: Operator = null;
  selectedProgram: Program = null;
  selectedProduct: Product = null;
  selectedProductFull: Product = null;

  loadedPrograms:boolean = false;
  loadedProducts:boolean = false;

  availableWorkflows = [];

  currentWorkflow = null;

  workflowConfigRawData = {};
  cannedWorkflows = [];

  showUFRGroup = false;
  BASE_GROUPS = ['standard', 'notification', 'schedule'];

  private ngUnsubscribe: Subject<any> = new Subject();

  ngOnInit() {

    const controller = this;

    //GET TEMPLATES
    controller.eventManagementService.get('/v1/templates?filter=DEMAND_RESPONSE', null).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      controller.allTemplates.NOTIFICATION = response.data;

      //There will always only be one template for schedule and it doesn't exist in content library, so just hardcode it
      controller.allTemplates.SCHEDULE = [{
        product_line: "DEMAND_RESPONSE",
        display_label: "DER.OS Schedule",
        template_name: "DER_OS_SCHEDULE"
      },
      {
        product_line: "DEMAND_RESPONSE",
        display_label: "OADR Schedule",
        template_name: "OADR_SCHEDULE"
      }
      ];
    }, error => {
      this.sharedService.popError("Error getting templates");
      console.dir("Error getting templates ");
      console.dir(error);
    });

    //GET FILTER TYPES
    controller.eventManagementService.get('/v1/schedule_filter_types', null).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
      controller.allFilters.SCHEDULE[0] = response.data;
      controller.allFilters.SCHEDULE[1] = response.data;
      controller.scheduleFilters = response.data
      this.sharedService.sortAlphabetically(controller.allFilters.SCHEDULE[0], "display_label");
      this.sharedService.sortAlphabetically(controller.allFilters.SCHEDULE[1], "display_label");
    }, error => {
      this.sharedService.popError("Error getting schedule filter types");
      console.dir("Error getting schedule filter types");
      console.dir(error);
    });

    //GET FILTER TYPES
    controller.eventManagementService.get('/v1/filter_types', null).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {
     // controller.allFilters.NOTIFICATION = response.data;
      controller.notificationFilters = response.data;
      this.sharedService.sortAlphabetically(controller.allFilters.NOTIFICATION[0], "display_label");
      this.sharedService.sortAlphabetically(controller.allFilters.NOTIFICATION[1], "display_label");
      this.sharedService.sortAlphabetically(controller.allFilters.NOTIFICATION[2], "display_label");
      this.sharedService.sortAlphabetically(controller.allFilters.NOTIFICATION[3], "display_label");
      this.sharedService.sortAlphabetically(controller.allFilters.NOTIFICATION[4], "display_label");
    }, error => {
      this.sharedService.popError("Error getting filter types");
      console.dir("Error getting filter types");
      console.dir(error);
    });

    //GET OPERATORS
    controller.eventManagementService.get('/v1/operator_hierarchy', null).pipe(takeUntil(this.ngUnsubscribe)
    ).subscribe(response => {

      controller.allOperators = response.data;

      this.sharedService.sortAlphabetically(controller.allOperators, "display_label");

        controller.eventManagementService.get('/v1/workflow?show_default=true&show_configs=true', {}).pipe().subscribe(
          response => {
            this.createWorkfowConfigData(function() {
              //Load the canned workflows
              response.data.forEach(function(cannedWorkflow) {
                controller.cannedWorkflows.push({
                  display_label: cannedWorkflow.display_label,
                  workflow_id: cannedWorkflow.workflow_id,
                  configs: controller.setConfigs(cannedWorkflow),
                  is_custom_ind: cannedWorkflow.is_custom_ind,
                  groups: cannedWorkflow.groups,
                });
              });

              controller.sharedService.sortAlphabetically(controller.cannedWorkflows, "display_label");

            });
          },
          error => {
            console.dir("Error getting canned workflows: ");
            console.dir(error);
          }
        );

    }, error => {
      this.sharedService.popError("Error getting Product Hierarchy");
      console.dir("Error getting operators hierarchy ");
      console.dir(error);
    });

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onOperatorSelect(event): void {
    const cont = this;
    cont.loadedProducts = false;

    cont.selectedProgram = null;
    cont.selectedProduct = null;
    cont.currentWorkflow = null;

    cont.allPrograms = cont.selectedOperator.programs;
    cont.loadedPrograms = true;

    this.sharedService.sortAlphabetically(cont.allPrograms, "display_label");
  }

  onProgramSelect(event):void {
    const cont = this;
    cont.selectedProduct = null;
    cont.currentWorkflow = null;

    cont.allProducts = cont.selectedProgram.products;

    this.sharedService.sortAlphabetically(cont.allProducts, "display_label");

    cont.loadedProducts = true;
  }

  onProductSelect(event):void {
    const controller = this;

    this.currentWorkflow = null;

    const getProduct$ = controller.eventManagementService.get('/v1/product/' + controller.selectedProduct.id, {}).pipe(map(({data}) => data), catchError(err => of({isError: true, error: err})));
    const getWorkflow$ = controller.eventManagementService.get('/v1/workflow/?show_configs=true&product_id=' + this.selectedProduct.id, {}).pipe(catchError(err => of({isError: true, error: err})));
    this.BASE_GROUPS = ['standard', 'notification', 'schedule'];
    forkJoin(getProduct$, getWorkflow$).pipe().subscribe(
      ([product, workflow]) => {
        if(product) {
          controller.selectedProductFull = product;
          controller.showUFRGroup = controller.selectedProductFull.underfrequency_product;
        }

        if(!workflow.isError) {
          controller.selectedProduct.workflow_id = workflow.data.workflow_id;

          //If the program we selected is tied to a Custom Workflow
          if (workflow.data.is_custom_ind) {
            controller.availableWorkflows = [
              {
                display_label: workflow.data.display_label,
                workflow_id: workflow.data.workflow_id,
                configs: controller.setConfigs(workflow.data),
                is_custom_ind: workflow.data.is_custom_ind,
                groups: workflow.data.groups,
              }];

            // controller.availableWorkflows = controller.availableWorkflows.concat(JSON.parse(JSON.stringify(controller.cannedWorkflows)));
            let filteredCannedWorkflows = controller.filterCannedWorkflows();
            controller.availableWorkflows = controller.availableWorkflows.concat(filteredCannedWorkflows);
            controller.setCurrentWorkflow(controller.availableWorkflows[0]);

          }
          //If the program we selected is tied to a Canned Workflow
          else {
            let customWorkflow = {
              display_label: "Custom",
              workflow_id: -1,
              configs: controller.setConfigs(null, false),
              is_custom_ind: true,
              groups: this.BASE_GROUPS,
            };

            if (controller.showUFRGroup === true && customWorkflow.groups.indexOf('ufr') == -1) {
              customWorkflow.groups.push('ufr');
            }

            controller.availableWorkflows = [customWorkflow];

            // controller.availableWorkflows = controller.availableWorkflows.concat(JSON.parse(JSON.stringify(controller.cannedWorkflows)));
            let filteredCannedWorkflows = controller.filterCannedWorkflows();
            controller.availableWorkflows = controller.availableWorkflows.concat(filteredCannedWorkflows);
            controller.setCurrentWorkflow(controller.availableWorkflows.find(function (wf) {
              return wf.workflow_id === workflow.data.workflow_id;
            }));

          }
        } else {
          controller.selectedProduct.workflow_id = '-1';
          console.log(workflow.error)
          //No workflows associated with this program, so create a custom one
          if (workflow.error.status == 404 || workflow.error.code == 404) {

            let customWorkflow = {
              display_label: "Custom",
              workflow_id: -1,
              configs: controller.setConfigs(null, false),
              is_custom_ind: true,
              groups: controller.BASE_GROUPS,
            };

           // if (controller.showUFRGroup === true) {
            //  customWorkflow.groups.push('ufr');
           // }

            controller.availableWorkflows = [customWorkflow];

            // controller.availableWorkflows = controller.availableWorkflows.concat(JSON.parse(JSON.stringify(controller.cannedWorkflows)));
            let filteredCannedWorkflows = controller.filterCannedWorkflows();
            controller.availableWorkflows = controller.availableWorkflows.concat(filteredCannedWorkflows);
            controller.setCurrentWorkflow(controller.availableWorkflows[0]);

          } else {
            console.dir("Error getting workflow_product: ");
            console.dir(workflow.error);
          }
        }
      }
    );
  }

  getGroupNames(configs) {
    const names = Object.keys(configs);

    const firstElement = "standard";
    const secondElement = "ufr";
    names.sort(function(x,y){
      if(x == firstElement || y == firstElement) {
        return x == firstElement ? -1 : y == firstElement ? 1 : 0;
      }
      return x == secondElement ? -1 : y == secondElement ? 1 : 0;

    });

    return names;
  }

  getStatName(group) {
    return group == 'ufr' ? 'UNDERFREQUENCY' : group.toUpperCase();
  }

  setConfigs(workflow, loading = true) : any {
    this.BASE_GROUPS = ['standard', 'notification', 'schedule'];
    const controller = this;

    //This is a dictionary of all WORKFLOW_STATEs and their blank corresponding workflow_state_config objects
    let allStateConfigs = JSON.parse(JSON.stringify(controller.workflowConfigRawData));

    //We'll add all of this workflow's relevant workflow_state_configs to this object and return it. So if it's a standard workflow
    // it will end up with only standard workflow_states. If it's a UFR workflow, it will have standard and UFR workflow_states
    let relevantConfigs = {};

    if(loading) {
      const setConfigsByGroup = function(group) {
        allStateConfigs[group].forEach(currentConfig => {
          const savedWorkflowConfig = workflow.workflow_configs.find(function(c) {
            return c.workflow_state_name === currentConfig.workflow_state_name;
          });

          if(savedWorkflowConfig !== null && savedWorkflowConfig !== undefined) {
            currentConfig.workflow_config_id = savedWorkflowConfig.workflow_config_id;
            currentConfig.is_automated_ind = savedWorkflowConfig.is_automated_ind;
            currentConfig.offset_minutes = savedWorkflowConfig.offset_minutes;
            currentConfig.is_included_ind = savedWorkflowConfig.is_included_ind;
            currentConfig.offset_summary = controller.updateOffsetSummary(currentConfig, currentConfig.offset_minutes);
            currentConfig.parameter_name = savedWorkflowConfig.parameter_name;
            currentConfig.filter_type = savedWorkflowConfig.filter_type;
          }

          //We've copied our saved workflow_state_config values to the currentConfig object, now add it to our 'relevantConfigs' object
          if(!Array.isArray(relevantConfigs[group])) {
            relevantConfigs[group] = [];
          }
          relevantConfigs[group].push(currentConfig);

        });
      };

      //For every group in this workflow, grab the blank workflow_state_configs from our dictionary and add it to 'relevantConfigs'
      workflow.groups.forEach(group => {
        setConfigsByGroup(group.toLowerCase());
      });
    } else {
      let groups = this.BASE_GROUPS;

      if(controller.showUFRGroup === true) {
        groups.push('ufr');
      }

      //We aren't loading the configs from the DB, so just copy the relevant configs over to our 'relevantConfigs' array
      groups.forEach(group => {
        allStateConfigs[group].forEach(currentConfig => {
          if(!Array.isArray(relevantConfigs[group])) {
            relevantConfigs[group] = [];
          }
          relevantConfigs[group].push(currentConfig);
        });
      })
    }

    return relevantConfigs;
  }

  filterCannedWorkflows() {
    const controller = this;
    let allCanned = JSON.parse(JSON.stringify(this.cannedWorkflows));

      for(let i = allCanned.length - 1; i >= 0; i--) {
        let workflowGroups = allCanned[i].groups.map(g => g.toLowerCase());
        let isUFR = workflowGroups.indexOf('ufr') > -1 || workflowGroups.indexOf('UFR') > -1;
        if((isUFR && !controller.showUFRGroup) || (!isUFR && controller.showUFRGroup)) {
          allCanned.splice(i,1);
        }
    }

    return allCanned;
  }

  onWorkflowSelect(event): void {
    this.setCurrentWorkflow(this.availableWorkflows.find(function(workflow) {
      return workflow.workflow_id.toString() === event.target.value.toString();
    }));
  }

  createWorkfowConfigData(callback): void {
    const controller = this;

    this.eventManagementService.get('/v1/workflow_state?group=all', {}).pipe()
      .subscribe(
        response => {
          response.data.forEach(function (wfs) {

            if (wfs.group === null || wfs.group === '') {

              if(!Array.isArray(controller.workflowConfigRawData['standard'])) {
                controller.workflowConfigRawData['standard'] = [];
              }

              let rawData = {
                workflow_state_name: wfs.workflow_state_name,
                display_label: wfs.display_label,
                is_included_ind: wfs.always_included,
                is_automated_ind: false,
                offset_minutes: 0,
                offset_from: wfs.offset_from,
                alwaysIncluded: wfs.always_included,
                parameter_name: null,
                filter_type: null
              };

              switch(wfs.offset_from) {
                case 'Start':
                  rawData['offset_summary'] = "0 Hour(s) and 0 Minute(s) before " + wfs.offset_from + " time";
                  break;
                case 'End':
                case 'Trip':
                  rawData['offset_summary'] = "0 Hour(s) and 0 Minute(s) after " + wfs.offset_from + " time";
                  break;
              }

              controller.workflowConfigRawData['standard'].push(rawData);

            } else {

              if(!Array.isArray(controller.workflowConfigRawData[wfs.group.toString().toLowerCase()])) {
                controller.workflowConfigRawData[wfs.group.toString().toLowerCase()] = [];
              }

              let rawData = {
                workflow_state_name: wfs.workflow_state_name,
                display_label: wfs.display_label,
                is_included_ind: wfs.always_included,
                is_automated_ind: false,
                offset_minutes: 0,
                offset_from: wfs.offset_from,
                alwaysIncluded: wfs.always_included,
                parameter_name: null,
                filter_type: null
              };

              switch(wfs.offset_from) {
                case 'Start':
                  rawData['offset_summary'] = "0 Hour(s) and 0 Minute(s) before " + wfs.offset_from + " time";
                  break;
                case 'End':
                case 'Trip':
                  rawData['offset_summary'] = "0 Hour(s) and 0 Minute(s) after " + wfs.offset_from + " time";
                  break;
              }

              controller.workflowConfigRawData[wfs.group.toString().toLowerCase()].push(rawData);
            }
          });

          callback();
        },
        error => {
          console.dir("Error getting workflow_states: ");
          console.dir(error);
        });
  }

  setCurrentWorkflow(workflow):void {
    this.currentWorkflow = JSON.parse(JSON.stringify(workflow));
    if(workflow.configs.schedule?.length){
      for(let i = 0; i<workflow.configs.schedule.length; i++){
        let wf = workflow.configs.schedule[i]
        this.onTemplateSelect('schedule', wf.parameter_name, wf.workflow_state_name)
      }
    }
    if(workflow.configs.notification?.length) {
      for (let i = 0; i < workflow.configs.notification.length; i++) {
        let wf = workflow.configs.notification[i]
        this.onTemplateSelect('notification', wf.parameter_name, wf.workflow_state_name)
      }
    }
  }

  reset(): void {
    const controller = this;

    this.setCurrentWorkflow(this.availableWorkflows.find(function(workflow) {
      return workflow.workflow_id.toString() === controller.selectedProduct.workflow_id.toString();
    }));
  }

  //Use this to set the default workflow stats for the program after saving. So if reset() is used, it resets back to the saved state instead
  //of the initial state the workflow was in when the page was loaded
  updateDefaultWorkflow(): void {
    this.onProductSelect({target: { value: this.selectedProduct.id } });
  }

  onSubmit({value, valid} ): void {
    const controller = this;

    if(this.currentWorkflow.is_custom_ind) {
      let workflowObj = {
        display_label: this.currentWorkflow.display_label,
        product_id: this.selectedProduct.id,
        is_custom_ind: true,
        workflow_configs: [],
        groups: this.currentWorkflow.groups
      };

      //We have to map the configs JSON object back to an array. Currently it's formatted like { standard: [array of configs], ufr: [array of configs] }
      let configsToSave = [];
      Object.values(this.currentWorkflow.configs).forEach(arr => {
        configsToSave = configsToSave.concat(arr);
      });

      //If we're updating an existing workflow
      if (this.currentWorkflow.workflow_id !== -1) {

        configsToSave.forEach(function (config) {
          workflowObj.workflow_configs.push({
            workflow_state_name: config.workflow_state_name,
            is_included_ind: config.is_included_ind,
            is_automated_ind: config.is_automated_ind,
            offset_minutes: config.offset_minutes,
            workflow_config_id: config.workflow_config_id,
            parameter_name: config.parameter_name,
            filter_type: config.filter_type
          });
        });

        this.loadingScreen.activate(
          new Promise(function (resolve, reject) {  resolve(controller.eventManagementService.put('/v1/workflow/' + controller.currentWorkflow.workflow_id, workflowObj, null)); }),
          function() {
            controller.updateDefaultWorkflow();
          },
          function(error) {
            return error;
          },
          false, true);
      }
      else {

        configsToSave.forEach(function (config) {
          workflowObj.workflow_configs.push({
            workflow_state_name: config.workflow_state_name,
            display_label: config.display_label,
            is_included_ind: config.is_included_ind,
            is_automated_ind: config.is_automated_ind,
            offset_minutes: config.offset_minutes,
            parameter_name: config.parameter_name,
            filter_type: config.filter_type
          });
        });

        this.loadingScreen.activate(
          new Promise(function (resolve, reject) {  resolve(controller.eventManagementService.post('/v1/workflow', workflowObj, null)); }),
          function() {
            controller.updateDefaultWorkflow();
          },
          function(error) {
            return error;
          },
          false, true);
      }
    }
    else {
      this.loadingScreen.activate(
        new Promise(function (resolve, reject) {  resolve(controller.eventManagementService.put('/v1/workflow/'+controller.currentWorkflow.workflow_id+'/product/' + controller.selectedProduct.id, null,null)); }),
        function() {
          controller.updateDefaultWorkflow();
        },
        function(error) {
          return error;
        },
        false, true);
    }
  }

  onMinutesChange(event, config): void {
    const value = event.target.value;

    config.offset_summary = this.updateOffsetSummary(config, value);
  }

  updateOffsetSummary(config, totalMinutes): string {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;

    switch(config.offset_from) {
      case 'Start':
        return hours + " Hour(s) and " + minutes + " Minute(s) before " + config.offset_from + " time";
      case 'End':
      case 'Trip':
        return hours + " Hour(s) and " + minutes + " Minute(s) after " + config.offset_from + " time";
    }
  }

  onTemplateSelect(group, parameter, workflow_state_name){
    let indexString = workflow_state_name.slice(-1);
    let index = Number(indexString)-1;


    if(group === 'schedule'){
      if(parameter === 'DER_OS_SCHEDULE'){
        this.allFilters['SCHEDULE'][index] = _.filter(this.scheduleFilters, ['code', 'ALL_ACTIVE_STORAGE' ])
      } else {
        this.allFilters['SCHEDULE'] [index]= _.filter(this.scheduleFilters, ['code', 'ALL_ACTIVE_OADR' ])
      }
    } else {
      if(this.currentWorkflow.is_custom_ind){
        this.allFilters['NOTIFICATION'][index] = _.filter(this.notificationFilters, (o)=>{return o.code != 'MANUAL'});
      } else {
        this.allFilters['NOTIFICATION'][index] = this.notificationFilters;
      }
    }

  }
}
