import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private toastr: ToastrService) { }

  transformParams(params) {

    if(!params) {
      return {};
    }

    // Remove any nulls or undefineds. HttpClient doesn't like them
    Object.keys(params).forEach(function(param) {
      if (params[param] === null || params[param] === undefined) {
        delete params[param];
      }
    });

    // HttpClient expects this formatting
    return { params: params };
  }

  sortAlphabetically(array, field) {
    array?.sort(function(a, b){

      if(!a[field] || a[field].toLowerCase() < b[field].toLowerCase()) { return -1; }
      if(a[field].toLowerCase() > b[field].toLowerCase()) { return 1; }
      return 0;
    });

    return array;
  }

  popError(message) {
    this.toastr.error(message);
  }

  popSuccess(message) {
    this.toastr.success(message);
  }
}
