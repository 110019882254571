import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {environment} from "../environments/environment";
import { RedirectGuard } from './guards/redirect.guard';
import {MainComponent} from "./components/main/main.component";

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'logout', canActivate: [RedirectGuard], data: {externalUrl: environment.login_url, logout: true}, component: MainComponent},
  { path: '**', redirectTo: '/'},
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
