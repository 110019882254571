import { Component } from '@angular/core';
import {UserService} from "./user.service";
import { combineLatest, NEVER, Observable } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import {HttpErrorResponse} from "@angular/common/http";
import { AuthService, GlobalNavService, MenuData } from 'ngx-global-nav';
import {TranslateService} from '@ngx-translate/core';
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  readonly menuData$: Observable<MenuData>;
  navUrl = '';
  constructor(public translate: TranslateService,
              private userService:UserService,
              private authService: AuthService,
              private globalNavService: GlobalNavService,) {

    // translate.setDefaultLang('en_US');
    const globalNavUrl = environment.globalNavUrl;
    this.navUrl = globalNavUrl;
    // global-nav
    this.globalNavService.userData$.subscribe((user) => {
      if(user !== null) {
        this.userService.formatUser(user);
      }
    });
  }

}
