import { Component, OnInit, Renderer2 } from '@angular/core';
import {Observable} from "rxjs/internal/Observable";

export enum State {
  loading, error, success
}

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.pug',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent implements OnInit {

  active:boolean = false;
  saving:boolean = false;
  StateEnum = State;
  loaderState = State.loading;
  errorMessage:string = "";
  body = null;

  constructor(private renderer: Renderer2) {

  }

  ngOnInit() {
  }

  //Currently only works for error state. If we allow the user to manually close with the success state we need to think of a way to avoid the success method potentially being called twice (once on click, and once when the $timeOut is done)
  canClose(): boolean {
    return this.active && this.loaderState === this.StateEnum.error;
  }

  closeManually(): void {
    const controller = this;
    if(this.canClose()) {
      controller.close();
    }
  }

   close(): void {
    this.active = false;
    this.renderer.removeClass(document.body, 'ovh');
   }

  //chainLoad allows us to chain multiple loading animations together without displaying a 'success' banner until the last one
  //true if this isn't the final loading call
  //false if we want to show 'success' when this is done
   activate(currentJob: Promise<any>, onSuccess, onError, chainLoad, saving): void {

    this.renderer.addClass(document.body, 'ovh');

    this.loaderState = this.StateEnum.loading;
    this.active = true;
    this.errorMessage = null;
    this.saving = saving;


    const controller = this;

     currentJob.then(function(response) {

       response.pipe()
         .subscribe(
           response => {

             controller.success(function() { onSuccess(response); }, chainLoad);
           },
           error => {

             if(error === null || error.error === null || error.error.message === null) {
               error = { error: {message: null} };
             }

             let tempMsg = onError(error);

             controller.error(typeof(tempMsg) === 'string' ? tempMsg : error.error.message);
           });
     });
  }

  success (onSuccess, chainLoad): void {

    const controller = this;

    //Keep going with the next loader
    if(chainLoad) {
      onSuccess();
    }
    else { //Delay and show the success banner
      setTimeout(function () {
        controller.loaderState = controller.StateEnum.success;
      }, 1);

      //Auto dismiss after 3 seconds
      setTimeout(function () {
        onSuccess();
        controller.close();

      }, 2000);
    }

  }

  error (errorMsg): void {
    const controller = this;

    setTimeout(function() {
      controller.loaderState = controller.StateEnum.error;
      controller.errorMessage = errorMsg || 'An error appeared!';
    }, 1);
  }

}
