import { Injectable, Injector } from '@angular/core';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private cookieService: CookieService;

  constructor(private injector: Injector) {
    this.cookieService = this.injector.get(CookieService);
  }

  logout() {
    this.cookieService.deleteAll('/', environment.openAmCookieDomain);
    let loc = window.location.href;
    let gotoParam = loc.indexOf('?') > -1 ? '&' : '?';
    gotoParam += 'goto=' + encodeURIComponent(loc) + '&realm=enelx';
    window.open(environment.login_url + gotoParam, '_self');
  }
}

import {Observable} from "rxjs/index";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import {ToastrService} from "ngx-toastr";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private toastr: ToastrService, private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.method === "OPTIONS") {
      // debugger;
      return next.handle(request);
    } else {
      request = request.clone({
        withCredentials: true
      });

      // console.log(request);
      return next.handle(request).pipe(tap(() => {},
        (err:any) => {
          if(err.status !== 401){
            if(err.status == 400 || err.status == 403)
            {
              console.log(err);
              this.toastr.error( JSON.stringify(err), null, {
                timeOut: 20000
              });
            }
            return;
          }
          this.authService.logout();
        }));
    }

  }
}
