
import {Injectable, Injector} from '@angular/core';
import { Location } from '@angular/common'
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

  constructor(private injector: Injector, private location: Location) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const targetURL = next.data ? next.data.externalUrl : null;
    if (!targetURL) {
      return false;
    }
    if (next.data && next.data.logout) {
      this.injector.get(AuthService).logout();
    }

    let loc = window.location.href;
    let gotoParam = loc.indexOf('?') > -1 ? '&' : '?';
    gotoParam += 'goto=' + encodeURIComponent(loc) + '&realm=enelx';

    window.open(targetURL + gotoParam, '_self');
    return true;
  }
}
