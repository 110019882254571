// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: 'https://event-management-api.dev-apps.enelx.net',
  login_url: 'http://openam.dev-apps.enelx.net/openam/XUI/',
  openAmCookieDomain: 'enelx.net',
  environment: 'dev',
  globalNavUrl: 'https://global-nav-v2.dev-svcs.enernoc.net/api/v2',

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
